import Vue from 'vue'
import axios from 'axios'
import router from '@/router'
import qs from 'qs'
import isPlainObject from 'lodash/isPlainObject'

// 默认API地址， 请在.env文件里配置
export let baseURL = process.env.VUE_APP_APIURL || window.SITE_CONFIG['apiURL']
if (process.env.NODE_ENV !== 'production') {
  baseURL= '/operation'
} else {
  baseURL += '/operation'
}

const http = axios.create({
  baseURL,
  timeout: 1000 * 180,
  withCredentials: true
})

/**
 * 请求拦截
 */
http.interceptors.request.use(config => {
  if (!config.headers) config.headers = {}
  // 默认参数
  var defaults = {}
  if (config.method === 'get') {
    const params = config.params
    // 移除空白的参数
    for (let i in params) {
      if (params[i] === '') {
        delete params[i]
      }
    }
    // 防止缓存，GET请求默认带_t参数
    defaults['_t'] = Date.now()
  }
  if (isPlainObject(config.params)) {
    config.params = {
      ...defaults,
      ...config.params
    }
  }
  if (isPlainObject(config.data)) {
    config.data = {
      ...defaults,
      ...config.data
    }
    if (/^application\/x-www-form-urlencoded/.test(config.headers['content-type'])) {
      config.data = qs.stringify(config.data)
    }
  }
  return config
}, error => {
  return Promise.reject(error)
})

http.interceptors.response.use(res => {
  let { data } = res
  if (data.code === 401) router.push({ name: 'login' })
  return res
})

if (!Vue.prototype.$http) Vue.prototype.$http = http

export default http

export function getUrl (p) {
  let b = baseURL
  if (b.startsWith('/')) {
    b = location.origin + b
  }
  if (!b.endsWith('/')) {
    b += '/'
  }
  console.log(b, p)
  const ur = new URL(p, b)
  return ur.href
}