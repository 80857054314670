import Vue from 'vue'
import _ from 'lodash'
import { errorCatcher } from '@/lib/utils'

console.log(
  `%c mts-admin %c Ver.${process.env.VUE_APP_VERSION} %c ${process.env.VUE_APP_BUILDTIME}`,
  'background:#35495e ; padding: 1px; border-radius: 3px 0 0 3px;  color: #fff',
  'background:#41b883 ; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff',
  'background:transparent'
)

const methods = {
  g$formValidate (name) {
    const form = this.$refs[name]
    if (!form) return Promise.reject(new Error('无效的表单'))
    const _f = (resolve, reject) => {
      form.validate((valid) => {
        if (!valid) reject(new Error('请根据提示修改'))
        else resolve()
      })
    }
    return new Promise(_f)
  },
  g$notify(message, title, cfg = {}) {
    const def = {
      position: 'bottom-right',
      duration: 5000,
      title: '提示'
    }
    if (message instanceof Object) {
      cfg = message
      message = undefined
      title = undefined
    }
    if (title instanceof Object) {
      cfg = title
      title = undefined
    }
    return this.$notify(_.merge({}, def , cfg, { message, title }))
  }
}

Vue.mixin({
  methods
})
Vue.config.errorHandler = errorCatcher
window.onerror = (err, url, line) => errorCatcher(err, { url, line })