<template>
  <div :class="`mts-navbar ${navbarLayout}`">
    <div class="mts-navbar__header">
      <h1 class="mts-navbar__brand" @click="goHome">
        <!-- <img class="brandIcon" src="@/assets/img/logo.png" alt=""> -->
        <a class="brandFull" href="javascript:;">管理平台</a>
        <a class="brandMini" href="javascript:;"></a>
      </h1>
    </div>
    <nav class="mts-navbar__body">
      <el-menu class="mts-navbar__menu mr-auto" mode="horizontal">
        <el-menu-item index="1" @click="foldSidebar">
          <svg class="icon-svg mts-navbar__icon-menu iconSwitch" aria-hidden="true">
            <use xlink:href="#icon-outdent"></use>
          </svg>
        </el-menu-item>
        <el-menu-item index="2" @click="refresh()">
          <svg class="icon-svg mts-navbar__icon-menu iconRefresh" aria-hidden="true">
            <use xlink:href="#icon-sync"></use>
          </svg>
        </el-menu-item>
        <el-menu-item index="3" @click="goHome">
          <svg class="icon-svg mts-navbar__icon-menu iconHome" aria-hidden="true">
            <use xlink:href="#icon-home"></use>
          </svg>
          <strong class="siteName">{{title}}</strong>
        </el-menu-item>
      </el-menu>
      <el-menu class="mts-navbar__menu" mode="horizontal">
        <el-menu-item index="999" class="mts-navbar__avatar">
          <el-dropdown placement="bottom" :show-timeout="0" @command="handleFunc">
            <span class="el-dropdown-link">
              <img src="~@/assets/img/avatar.png">
              <span>{{ userInfo.username }}</span>
              <i class="el-icon-arrow-down"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-if="false" command="changePassword" icon="el-icon-lock">修改密码</el-dropdown-item>
              <el-dropdown-item command="logout" icon="el-icon-switch-button">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-menu-item>
      </el-menu>
    </nav>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'mainNavbar',
  inject: ['refresh'],
  data () {
    return {
      navbarLayout: 'navbarFull',
      title: process.env.VUE_APP_TITLE
    }
  },
  computed: {
    ...mapState('system', ['userInfo', 'sidebar', 'server_route']),
  },
  methods: {
    ...mapActions('system', ['logout', 'toggleFold']),
    handleFunc (name) {
      switch (name) {
        case 'logout':
          this.$confirm('确认退出此账号？', '退出登录', { type: 'warning' })
            .then(() => {
              this.logout().then(() => {
                location.reload()
              })
            })
          break
        case 'changePassword':
          // this.changePasswordModal.visible = true
          // this.changePasswordModal.form.password = ''
          // this.changePasswordModal.form.newPassword = ''
          break
      }
    },
    goHome () {
      this.$router.push({ name: 'home' })
    },
    foldSidebar () {
      this.toggleFold({ status: !this.sidebar.fold })
    }
  }
}
</script>
<style lang="scss" scoped>
  .siteName {
    display: inline-block;
    margin-left: .5em;
    font-weight: bold;
    font-size: 15px;
  }
</style>
