import Vue from 'vue'
import api from '@/lib/api'
import { getErrorFormat } from '@/lib/utils'
import { REPORT_INVENTORY_PAGE } from '@/store/mutation-types'
const apiReport = api.reportInventory

const mutations = {
  [REPORT_INVENTORY_PAGE] (state, data) {
    Vue.set(state, 'pageItems', data.records)
    Vue.set(state, 'current', parseInt(data.current))
    Vue.set(state, 'pages', parseInt(data.pages))
    Vue.set(state, 'size', parseInt(data.size))
    Vue.set(state, 'total', parseInt(data.total))
  }
}

const actions = {
  page ({ commit }, params) {
    return apiReport.page(params).then((data) => {
      switch (data.code) {
        case 0:
          commit(REPORT_INVENTORY_PAGE, data.data)
          return Promise.resolve(data)
        default:
          return getErrorFormat(data)
      }
    })
  },
  getOwnerList (vuex, params) {
    return apiReport.getOwnerList(params).then((data) => {
      switch (data.code) {
        case 0:
          return Promise.resolve(data)
        default:
          return getErrorFormat(data)
      }
    })
  },
  getTenantList (vuex, params) {
    return apiReport.getTenantList(params).then((data) => {
      switch (data.code) {
        case 0:
          return Promise.resolve(data)
        default:
          return getErrorFormat(data)
      }
    })
  }
}

export default {
  namespaced: true,
  mutations,
  actions,
  state: {
    pageItems: [],
    current: 1,
    pages: 0,
    size: 15,
    total: 0
  }
}