import { wordJoin } from '@/lib/utils'
const files = require.context('./', true, /\.js$/)

const modules = files.keys().reduce((modules, pathname) => {
  if (pathname === './index.js') return modules
  let name = pathname.replace(/^\.\/(.*)\.\w+$/, '$1')
  if (name.includes('/')) {
    let part = name.split('/')
    name = wordJoin(...part)
    if (name.endsWith('Index')) name = name.substring(0, name.length - 5)
  }
  // console.log(name)
  const value = files(pathname)
  modules[name] = value.default
  return modules
}, {})

export default modules