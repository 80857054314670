<template>
  <div id="Main" v-loading.fullscreen.lock="loading" element-loading-text="正在加载..." :class="['mts-mainPage', 'mts-wrapper', { 'sidebarFold': sidebar.fold }]">
    <template v-if="!loading">
      <main-navbar />
      <main-sidebar />
      <div class="content-wrapper">
        <main-content />
      </div>
    </template>
  </div>
</template>
<script>
import _ from 'lodash'
import { mapActions, mapState } from 'vuex'
import { storage } from '@/lib/utils'
import MainNavbar from './navbar'
import MainSidebar from './sidebar'
import MainContent from './content'
import { addRoutes } from '@/router'

export default {
  name: 'Main',
  components: {
    MainNavbar,
    MainSidebar,
    MainContent
  },
  provide () {
    return {
      // 刷新
      refresh () {
        this.$store.dispatch('system/refresh')
      }
    }
  },
  data () {
    return {
      loading: true,
      sidebarFold: false
    }
  },
  watch: {
    $route: 'routeHandle'
  },
  computed: {
    ...mapState('system', {
      contentTabs: s => s.mutiTab.opened,
      sidebar: 'sidebar',
      needRefresh: 'needRefresh'
    })
  },
  mounted () {
    this.windowResizeHandle()
    this.routeHandle(this.$route)
    this.getUserInfo().then(() => this.getMenu()).then(() => {
      this.loading = false
    }).catch((e) => {
      console.log(e)
      if (e.code === 401) {
        this.$router.push({ name: 'login' })
      }
    })
    //   .then(() => this.getPermissions())
    //   .then(() => this.getMenu())
    //   .then(() => {
    //     this.gotoLastPage()
        
    //   }).catch((e) => errorCatcher(e))
  },
  methods: {
    ...mapActions('system', ['navMenu', 'userInfo', 'permissions', 'parseRoute', 'addNewTab', 'changeTab', 'changeActivedMenu', 'toggleFold']),
    windowResizeHandle () {
      // 窗口变小时收侧栏
      const $body = document.documentElement
      const _resize = () => {
        this.sidebarFold = $body.clientWidth <= 992 || false
        this.toggleFold({ status: this.sidebarFold })
      }
      _resize()
      window.addEventListener('resize', _.debounce(() => _resize()))
    },
    gotoLastPage () {
      let last = storage.getItem('lastRouter')
      // console.log(last)
      if (last) this.$router.replace(last)
    },
    routeHandle (route) {
      if (!route.meta.isTab) {
        return false
      }
      const matched = this.contentTabs.find(item => item.name === route.name)
      if (matched) {
        this.changeTab(matched.name)
        this.changeActivedMenu(matched.menuId)
      } else {
        this.addNewTab(route).then(() => {})
      }
    },
    getMenu () {
      return this.$http.get('/currentLoginUserMenu').then(({ data: res }) => {
        console.log(res)
        let { code, data, msg } = res
        if (code !== 0) {
          return Promise.reject(new Error(msg))
        }
        let menu = _.cloneDeep(data)
        menu.sort((a, b) => a.sort - b.sort)
        let groups = _.groupBy(menu, (v) => v.pid)
        let conn = function (v, c) {
          if (!Array.isArray(v)) return undefined
          let r = _.cloneDeep(v)
          r.forEach((item) => {
            let id = item.id
            if (c) {
              item.parentName = c.name
            }
            let children = groups[id + '']
            if (Array.isArray(children) && children.length) {
              item.children = conn(children, item)
            } else {
              item.isLeaf = true
              item.children = undefined
            }
          })
          return r
        }
        let navItems = conn(groups['0'])
        console.log(navItems)
        this.navMenu(navItems)
        this.parseRoute(navItems).then((routes) => {
          addRoutes(routes)
        })
        // */
        return Promise.resolve()
      })
    },
    getUserInfo () {
      return this.userInfo().then(() => {
        // console.log('user info:', data)
      })
    },
    getPermissions () {
      return this.permissions().then(() => {
        // console.log('permissions:', data)
      })
    }
  }
}
</script>