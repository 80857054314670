import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/main'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'main',
    component: Main,
    redirect: { name: 'home' },
    meta: {
      title: '管理平台'
    },
    children: [{
      path: '/home',
      component: () => import('@/views/modules/home'),
      name: 'home',
      meta: {
        title: '首页',
        isTab: false
      }
    },
    { path: 'tenantInventory-view', component: () => import('@/views/modules/statistics/tenantInventory/view'), name: 'tenantInventory-view', meta: { title: '仓库库存详情', isTab: true } }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "userLogin" */ '@/views/pages/login.vue')
  },
  { path: '*', redirect: { name: 'main' } }
]
const defaultRoutes = {
  path: '/',
  name: 'main',
  component: Main,
  redirect: { name: 'home' },
  meta: {
    title: '管理平台'
  },
  children: []
}

const router = new VueRouter({
  routes
})

export function addRoutes (children) {
  children.forEach((v) => {
    v.component = () => import('@/views/' + v.componentUrl)
  })
  router.addRoutes([
    {
      ...defaultRoutes,
      name: 'main-dynamic-menu',
      children
    },
    { path: '*', redirect: { name: '404' } }
  ])
}

export default router
