import { v4 as getUuid } from 'uuid'
import storage from './storage'
import _ from 'lodash'

export function debounce (_f, ms = 1000) {
  return _.debounce(_f, ms, { leading: true, trailing: false })
}

export const apiRequiredCheck = (q, maps) => {
  const required = maps.filter((o) => o.required).map((o) => o.name)
  const hasOwnProperty = Object.prototype.hasOwnProperty.bind(q)
  required.forEach((n) => {
    if (!hasOwnProperty(n)) throw new Error('requiredCheckError')
  })
}

export function getErrorFormat (data) {
  console.log(data)
  let err = new Error(data.msg)
  err.code = data.code
  err.sourceObject = data
  return Promise.reject(err)
}

export function upperCaseJoin (arr, char = '_') {
  return arr.map((o) => o.toUpperCase()).join(char)
}

export function camelCaseJoin (arr, isPascal) {
  let word = ''
  arr.forEach((val, idx) => {
    if(idx === 0 && !isPascal) {
      word += val
    } else {
      word += val.charAt(0).toUpperCase()
      word += val.substr(1)
    }
  })
  return word
}

export function wordJoin (str) {
  let arr = [...arguments]
  if(arguments.length === 1 && str instanceof Array) {
    arr = str
  }
  return camelCaseJoin(arr)
}

export function dateFormat (fmt, dt) {
  if (!dt) {
    dt = new Date()
  } else if (!(dt instanceof Date)) {
    dt = new Date(dt)
  }
  let hour = dt.getHours()
  let ampm = hour > 12 ? 'pm' : 'am'
  var o = {
    'M+': dt.getMonth() + 1, // 月份
    'd+': dt.getDate(), // 日
    'h+': hour > 12 ? (hour - 12) : hour, // 12小时
    'H+': hour, // 24小时
    'm+': dt.getMinutes(), // 分
    's+': dt.getSeconds(), // 秒
    'q+': Math.floor((dt.getMonth() + 3) / 3), // 季度
    'S': dt.getMilliseconds(), // 毫秒
    'A': ampm.toUpperCase(), // 大写AMPM
    'a': ampm // 小写ampm
  }

  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (dt.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
    }
  }

  return fmt
}

export function errorCatcher (err, vm, info) {
  if (process.env.NODE_ENV === 'development' && !err.shown) {

    console.group(`${dateFormat('yyyy-MM-dd HH:mm:ss')} >>> 错误信息 ${err.message}`)
    if (info) console.error(info)
    if (vm) console.log(vm)
    console.error(err)
    if (err.sourceObject) console.log(err.sourceObject)
    console.groupEnd()
    err.shown = true
  }
  return Promise.reject(err)
}

export function filterProp (obj, items) {
  const ret = {}
  items.forEach((k) => {
    let v = obj[k]
    if (v instanceof Object || v instanceof Array) {
      v = _.cloneDeep(v)
    }
    ret[k] = v
  })
  return ret
}

export function removeProp (obj, items) {
  const ret = _.cloneDeep(obj)
  items.forEach((k) => {
    if (ret[k]) delete ret[k]
  })
  return ret
}

export function dataURLToArr (u) {
  let arr = u.split(','),
    // mime = arr[0].match(/:(.*?);/)[1],
    buf = Buffer.from(arr[1], 'base64'),
    u8arr = new Uint8Array(buf.data)
  return u8arr.buffer
}
export function dataURLToBlob (u) {
  // console.log('datarul', u)
  let arr = u.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    buf = Buffer.from(arr[1], 'base64'),
    u8arr = new Uint8Array(buf.data)
  return new Blob([u8arr], { type: mime })
}
export function blobToDataURL (b) {
  const blob = (b instanceof Blob) ? b : new Blob(b)
  const reader = new FileReader()
  let ret = ''
  reader.onload = (e) => {
    ret = e.target.result
  }
  reader.readAsDataURL(blob)
  return ret
}

export function getValueType(value) {
  let ret = Object.prototype.toString.call(value)
  return ret.substring(8, ret.length - 1).toLowerCase()
}

export function typeCheck(type, value) {
  switch (type) {
    case 'integer':
      if (getValueType(value) === 'number') {
        return parseInt(value) === value
      }
      return false
    case 'float':
    case 'double':
      if (getValueType(value) === 'number') {
        return value % 1 !== 0
      }
      return false
    case 'date':
    case 'datetime':
      if (getValueType(value) === 'string') {
        return !Number.isNaN(Date.parse(value))
      }
      return false
    default:
      return getValueType(value) === type
  }
}

export const validate = {
  url: (s) => /^http[s]?:\/\/.*/.test(s),
  phone: (s) => /^(0[0-9]{2,3}-)?[0-9]{7,8}$/.test(s),
  mobile: (s) => /^1[0-9]{10}$/.test(s),
  email: (s) => /^([a-zA-Z0-9._-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(s)
}

/**
 * 获取svg图标(id)列表
 */
export function getIconList () {
  var res = []
  document.querySelectorAll('svg symbol').forEach(item => {
    res.push(item.id)
  })
  return res
}

export function getClientHeight () {
  let clientHeight = 0
  if (document.body.clientHeight && document.documentElement.clientHeight) {
    clientHeight = (document.body.clientHeight < document.documentElement.clientHeight) ? document.body.clientHeight : document.documentElement.clientHeight
  } else {
    clientHeight = (document.body.clientHeight > document.documentElement.clientHeight) ? document.body.clientHeight : document.documentElement.clientHeight
  }
  return clientHeight
}

export { getUuid, storage }