import { getValueType, dataURLToArr, dataURLToBlob, blobToDataURL } from '../utils'
import _  from 'lodash'

const _this = {
  storagePrefix: process.env.VUE_APP_STORAGE_PREFIX || 'mts$',
  getKeyName () {
    let ret = [...arguments].join(':')
    return ret
  },
  setRelateMap (key, items) {
    if ('string' === typeof items) {
      items = [ items ]
    }
    let k = _this.getKeyName('[relate]', key)
    return _this.appendData(k, new Set(items))
  },
  clearRelateItems (key) {
    let k = _this.getKeyName('[relate]', key)
    let items = _this.getItem(k)
    if (items instanceof Set || items instanceof Array) {
      items.forEach((v) => {
        _this.removeItem(v)
      })
    } else if(items) {
      // 其他奇怪的格式
      console.log('wrong type:', items)
    }
    _this.removeItem(k)
  },
  removeItem (key, session) {
    if ('boolean' === typeof key) {
      session = key
      key = undefined
    }
    if (key) key = _this.storagePrefix + key
    const stor = session ? 'sessionStorage' : 'localStorage'
    return key ? window[stor].removeItem(key) : window[stor].clear()
  },
  getItem (key) {
    const e = _this.storagePrefix + key
    let val = localStorage.getItem(e) || sessionStorage.getItem(e)
    if (!val || !(val.startsWith('{') && val.endsWith('}'))) return val
    let ret = JSON.parse(val)
    val = ret.value
    switch (ret.type) {
      case 'set':
        val = new Set(ret.value)
        break
      case 'date':
        val = new Date(ret.value)
        break
      case 'regexp':
        val = new RegExp(ret.value)
        break
      case 'arraybuffer':
        val = dataURLToArr(ret.value)
        break
      case 'blob':
        val = dataURLToBlob(ret.value)
        break
    }
    return val
  },
  appendData (key, value, session) {
    let ov = _this.getItem(key)
    let vt = getValueType(value)
    if (ov !== undefined) {
      vt = getValueType(ov)
    }
    switch (vt) {
      case 'set':
        if (value instanceof Array || value instanceof Set) {
          value.forEach((v) => ov.add(v))
        } else {
          ov.add(value)
        }
        break
      case 'array':
        ov = ov.concat(value)
        break
      case 'object':
        if (!ov) ov = value
        else _.merge(ov, value)
        break
      case 'string':
        if (!ov) ov = [ value ]
        else ov = [ ov, value ]
        break
      default:
        console.error(`${vt} can't be append!`)
        return
    }
    return _this.setItem(key, ov, session)
  },
  deleteDataItem (key, name, session) {
    let value = _this.getItem(key)
    let vt = getValueType(value)
    switch (vt) {
      case 'object':
        if ('string' === typeof name) delete value[name]
        else if (name instanceof Array) {
          // TODO: 深度删除方式
        }
        break
      case 'array':
        if ('number' === typeof name) value.splice(name, 1)
        else if (name instanceof Array) {
          let arr = _.sortBy(name, (o) => parseInt(o))
          arr.reverse().forEach((idx) => value.splice(idx, 1))
        }
        break
      case 'set':
        value.delete(name)
        break
      default:
        console.error(`${vt} can't be remove!`)
        return
    }
    return _this.setItem(key, value, session)
  },
  setItem (key, value, session) {
    const type = getValueType(value)
    switch (type) {
      case 'set':
        value = [...value]
        break
      case 'arraybuffer':
        value = blobToDataURL([value])
        break
      case 'blob':
        value = blobToDataURL(value)
        break
      // TODO: 处理二进制数据
    }
    const date = Date.now()
    const e = _this.storagePrefix + key
    const stor = session ? 'sessionStorage' : 'localStorage'
    window[stor].setItem(e, JSON.stringify({
      key, date, value, type
    }))
  }
}

export default _this
