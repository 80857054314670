import Vue from 'vue'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

function changeDefault (e, p, v) {
  Element[e].props[p].default = v
}

changeDefault('Dialog', 'closeOnClickModal', false)
changeDefault('Dialog', 'closeOnPressEscape', false)
changeDefault('Pagination', 'layout', '->, total, sizes, prev, pager, next, jumper')
changeDefault('Pagination', 'pageSizes', () => [15, 30, 50, 100])

Vue.use(Element, {
  size: 'small'
})
