import http from '@/plugins/axios'

const _this = {
  page (params) {
    return http.get('erpUser', { params }).then(({ data: json }) => Promise.resolve(json))
  },
  updateStatus (form) {
    let { id, status } = form
    return http.put(`erpUser/${id}/updateStatus/${status}`).then(({ data: json }) => Promise.resolve(json))
  },
  resetPassword (form) {
    return http.post('erpUser/resetPassword', form).then(({ data: json }) => Promise.resolve(json))
  }
}

export default _this