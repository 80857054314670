import http from '@/plugins/axios'

const _this = {
  page (params) {
    return http.get('tenantAdmin', { params }).then(({ data: json }) => Promise.resolve(json))
  },
  add (form) {
    return http.post('tenantAdmin/add', form).then(({ data: json }) => Promise.resolve(json))
  },
  modify (form) {
    return http.post('tenantAdmin/edit', form).then(({ data: json }) => Promise.resolve(json))
  },
  updateStatus (form) {
    return http.put('tenantAdmin/updateStatus', form).then(({ data: json }) => Promise.resolve(json))
  },
  detail (id) {
    return http.get(`tenantAdmin/${id}`).then(({ data: json }) => Promise.resolve(json))
  },
  delete (id) {
    return http.delete(`tenantAdmin/${id}`).then(({ data: json }) => Promise.resolve(json))
  },
  tenantSearch (params) {
    return http.get('tenantAdmin/tenant', { params }).then(({ data: json }) => Promise.resolve(json))
  },
  checkMobile (mobile) {
    return http.get(`tenantAdmin/checkMobile/${mobile}`).then(({ data: json }) => Promise.resolve(json))
  },
  checkUsername (username) {
    return http.get(`tenantAdmin/checkUsername/${username}`).then(({ data: json }) => Promise.resolve(json))
  }
}

export default _this
