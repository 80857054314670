import http from '@/plugins/axios'

const _this = {
  page (params, cfg) {
    return http.get('inventory', { ...cfg, params }).then(({ data: json }) => Promise.resolve(json))
  },
  getOwnerList (params, cfg) {
    return http.get('inventory/owner', { ...cfg, params }).then(({ data: json }) => Promise.resolve(json))
  },
  getTenantList (params, cfg) {
    return http.get('inventory/getTenantList', { ...cfg, params }).then(({ data: json }) => Promise.resolve(json))
  }
}

export default _this