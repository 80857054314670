import http from '@/plugins/axios'

const _this = {
  getOwnerCount (params, cfg) {
    // 货主数量
    return http.get('home/getOwnerCount', { ...cfg, params }).then(({ data: json }) => Promise.resolve(json))
  },
  getProcessCodeCount (params, cfg) {
    // 福茶码赋码量
    return http.get('home/getProcessCodeCount', { ...cfg, params }).then(({ data: json }) => Promise.resolve(json))
  },
  getSaleOrderInfo (params, cfg) {
    // 茶叶交易金额统计
    return http.get('home/getSaleOrderInfo', { ...cfg, params }).then(({ data: json }) => Promise.resolve(json))
  },
  getSkuCount (params, cfg) {
    // 商品SKU数量
    return http.get('home/getSkuCount', { ...cfg, params }).then(({ data: json }) => Promise.resolve(json))
  },
  getSpuCount (params, cfg) {
    // 商品SPU数量
    return http.get('home/getSpuCount', { ...cfg, params }).then(({ data: json }) => Promise.resolve(json))
  },
  getTeaSaleTop (params, cfg) {
    // 茶叶销量排行
    return http.get('home/getTeaSaleTop', { ...cfg, params }).then(({ data: json }) => Promise.resolve(json))
  },
  getTenantCount (params, cfg) {
    // 仓库数量
    return http.get('home/getTenantCount', { ...cfg, params }).then(({ data: json }) => Promise.resolve(json))
  },
  getTenantWarehouseVolume (params, cfg) {
    // 仓库情况
    return http.get('home/getTenantWarehouseVolume', { ...cfg, params }).then(({ data: json }) => Promise.resolve(json))
  },
  getTotalWarehouseVolume (params, cfg) {
    // 仓库容量
    return http.get('home/getTotalWarehouseVolume', { ...cfg, params }).then(({ data: json }) => Promise.resolve(json))
  }
}

export default _this