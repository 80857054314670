import Vue from 'vue'
import api from '@/lib/api'
import { getErrorFormat, validate, filterProp, storage } from '@/lib/utils'
import { SYSTEM_LOGIN, SYSTEM_USERINFO, SYSTEM_MENU, SYSTEM_MENU_ROUTE, SYSTEM_MENU_FOLD, SYSTEM_MENU_ACTIVED, SYSTEM_PERMISSIONS, SYSTEM_TAB_ADD, SYSTEM_TAB_CHANGED, SYSTEM_TAB_REMOVE, SYSTEM_TAB_REMOVE_ALL } from '@/store/mutation-types'
const apiUser = api.systemUser

const SandBox = function () {
  // eslint-disable-next-line
  const apiURL = api.getUrl('./')
  this.eval = function (n) {
    // eslint-disable-next-line
    return eval(n)
  }
}

const tabDefault = {
  name: '',
  params: {},
  query: {},
  menuId: '',
  title: '',
  isTab: true,
  iframeURL: null
}

function addRoute (menuList, routes) {
  if (!routes) routes = []
  if (!menuList) menuList = []
  let temp = []
  menuList.forEach((item) => {
    const children = item.children
    if (children && children.length > 0) {
      temp = temp.concat(children)
      return
    }
    const route = {
      path: '',
      component: null,
      name: '',
      meta: {
        ...tabDefault,
        menuId: item.id,
        title: item.name
      }
    }
    let ur = item.path || ''
    if (ur.includes('{{')) {
      try {
        const sandBox = new SandBox()
        ur = ur.replace(/{{([^}}]+)?}}/g, (s1, s2) => sandBox.eval(s2))
      } catch (e) {
        console.log([item.name, ur])
        console.error(e)
        ur = ''
      }
    }
    if (validate.url(ur)) {
      route.path = `/iframe/${item.id}`
      route.name = `iframe-${item.id}`
      route.meta.iframeURL = ur
    } else {
      if (!ur) ur = '/empty'
      const sp = ur.split('/')
      // 去掉空的第一项
      if (!sp[0]) sp.shift()
      // 头两项相同的移出一项
      if (sp.length > 1 && sp[0] === sp[1]) {
        sp.shift()
      }
      const dir = sp.shift()
      const fname = sp.length ? sp.join('-') : ''
      route.path = ['', dir, fname].join('/')
      route.name = [dir, fname || 'index'].join('-')
      route.componentUrl = `modules${route.path}`
    }
    route.meta.name = route.name
    routes.push(route)
  })
  if (temp.length >= 1) {
    // console.log(routes)
    return addRoute(temp, routes)
  }
}

const mutations = {
  [SYSTEM_LOGIN] (state, data) {
    // console.log(data)
    if (!data || data.expire === -1) {
      // 如果传空，则退出登录
      state.isLogin = false
      storage.removeItem('isLogin')
      return
    }
    state.isLogin = true
    storage.setItem ('isLogin', true)
  },
  [SYSTEM_USERINFO] (state, data) {
    Vue.set(state, 'userInfo', data)
  },
  [SYSTEM_PERMISSIONS] (state, data) {
    Vue.set(state, 'permissions', data)
  },
  [SYSTEM_TAB_CHANGED] (state, id) {
    state.mutiTab.active = id
  },
  [SYSTEM_TAB_ADD] (state, tab) {
    state.mutiTab.opened.push(tab)
  },
  [SYSTEM_TAB_REMOVE] (state, name) {
    // console.log(name)
    const ret = state.mutiTab.opened.filter((item) => item.name !== name)
    Vue.set(state.mutiTab, 'opened', ret)
  },
  [SYSTEM_TAB_REMOVE_ALL] (state) {
    Vue.set(state.mutiTab, 'opened', [])
  },
  [SYSTEM_MENU_ACTIVED] (state, id) {
    state.sidebar.activeItem = id
  },
  [SYSTEM_MENU] (state, data) {
    Vue.set(state, 'navMenu', data)
    state.sidebar.menuLoaded = true
  },
  [SYSTEM_MENU_FOLD] (state, status) {
    state.sidebar.fold = status
  },
  [SYSTEM_MENU_ROUTE] (state, serverRoute) {
    state.route = serverRoute
  }
}

const actions = {
  login ({ commit }, form) {
    return apiUser.login(form).then((data) => {
      // console.log(data)
      switch (data.code) {
        case 0:
          commit(SYSTEM_LOGIN, data.data)
          return Promise.resolve(data)
        default:
          return getErrorFormat(data)
      }
    })
  },
  logout ({ commit }, form) {
    return apiUser.logout(form).then((data) => {
      switch (data.code) {
        case 0:
          // 清除token信息
          commit(SYSTEM_LOGIN)
          return Promise.resolve(data)
        default:
          return getErrorFormat(data)
      }
    })
  },
  navMenu ({ commit }, data) {
    commit(SYSTEM_MENU, data)
    return Promise.resolve(data)
  },
  userInfo ({ commit }, params) {
    return apiUser.info(params).then((data) => {
      switch (data.code) {
        case 0:
          commit(SYSTEM_USERINFO, data.data)
          return Promise.resolve(data)
        default:
          return getErrorFormat(data)
      }
    }).catch((e) => {
      switch (e.code) {
        case 401:
          commit(SYSTEM_LOGIN)
          return Promise.reject(e)
        default:
          return Promise.reject(e)
      }
    })
  },
  addNewTab ({ commit, dispatch }, route) {
    const tab = {
      ...tabDefault,
      ...route.meta,
      ...filterProp(route, ['name', 'params', 'query'])
    }
    // console.log(tab)
    if (!tab.name) return Promise.resolve(tab)
    commit(SYSTEM_TAB_ADD, tab)
    dispatch('changeTab', tab.name)
    dispatch('changeActivedMenu', tab.menuId)
    return Promise.resolve(tab)
  },
  removeTabs ({ commit }, tabs) {
    if (tabs.length === 0) {
      commit(SYSTEM_TAB_REMOVE_ALL)
    } else {
      tabs.forEach((tab) => {
        commit(SYSTEM_TAB_REMOVE, tab)
      })
    }
  },
  changeTab ({ commit }, id) {
    return commit(SYSTEM_TAB_CHANGED, id)
  },
  changeActivedMenu ({ commit }, id) {
    commit(SYSTEM_MENU_ACTIVED, id)
  },
  parseRoute ({ commit }, menuList) {
    const _f = (resolve, reject) => {
      const routes = []
      try {
        addRoute(menuList, routes)
        commit(SYSTEM_MENU_ROUTE, routes)
        return resolve(routes)
      } catch (e) {
        reject(e)
      }
    }
    return new Promise(_f)
  },
  toggleFold ({ commit }, params) {
    commit(SYSTEM_MENU_FOLD, params.status)
  },
  refresh ({ state }) {
    Vue.set(state, 'needRefresh', true)
    setTimeout(() => {
      Vue.set(state, 'needRefresh', false)
    }, 1)
  }
}

export default {
  namespaced: true,
  mutations,
  actions,
  state: {
    isLogin: false,
    userInfo: {},
    tokenInfo: {},
    needRefresh: false,
    sidebar: {
      menuLoaded: false,
      activeItem: null,
      fold: false
    },
    mutiTab: {
      active: null,
      opened: []
    },
    navMenu: [],
    permissions: null,
    route: []
  }
}