import Vue from 'vue'
import api from '@/lib/api'
import { getErrorFormat } from '@/lib/utils'
import { TENANT_PAGE, TENANT_STATUS_LIST, TENANT_TYPE_LIST } from '@/store/mutation-types'
const apiTenant = api.tenant

const mutations = {
  [TENANT_PAGE] (state, data) {
    Vue.set(state, 'pageItems', data.records)
    Vue.set(state, 'current', parseInt(data.current))
    Vue.set(state, 'pages', parseInt(data.pages))
    Vue.set(state, 'size', parseInt(data.size))
    Vue.set(state, 'total', parseInt(data.total))
  },
  [TENANT_STATUS_LIST] (state, data) {
    Vue.set(state, 'statusItems', data)
  },
  [TENANT_TYPE_LIST] (state, data) {
    Vue.set(state, 'typeItems', data)
  }
}

const actions = {
  save (vuex, form) {
    return apiTenant.add(form).then((data) => {
      switch (data.code) {
        case 0:
          return Promise.resolve(data)
        default:
          return getErrorFormat(data)
      }
    })
  },
  modify (vuex, form) {
    return apiTenant.modify(form).then((data) => {
      switch (data.code) {
        case 0:
          return Promise.resolve(data)
        default:
          return getErrorFormat(data)
      }
    })
  },
  statusList ({ commit }) {
    return apiTenant.statusList().then((data) => {
      switch (data.code) {
        case 0:
          commit(TENANT_STATUS_LIST, data.data)
          return Promise.resolve(data)
        default:
          return getErrorFormat(data)
      }
    })
  },
  typeList ({ commit }) {
    return apiTenant.typeList().then((data) => {
      switch (data.code) {
        case 0:
          commit(TENANT_TYPE_LIST, data.data)
          return Promise.resolve(data)
        default:
          return getErrorFormat(data)
      }
    })
  },
  updateStatus (vuex, form) {
    return apiTenant.updateStatus(form).then((data) => {
      switch (data.code) {
        case 0:
          return Promise.resolve(data)
        default:
          return getErrorFormat(data)
      }
    })
  },
  detail (vuex, id) {
    return apiTenant.detail(id).then((data) => {
      switch (data.code) {
        case 0:
          return Promise.resolve(data)
        default:
          return getErrorFormat(data)
      }
    })
  },
  delete (vuex, form) {
    let id = form[0]
    return apiTenant.delete(id).then((data) => {
      switch (data.code) {
        case 0:
          return Promise.resolve(data)
        default:
          return getErrorFormat(data)
      }
    })
  },
  page ({ commit }, params) {
    return apiTenant.page(params).then((data) => {
      switch (data.code) {
        case 0:
          commit(TENANT_PAGE, data.data)
          return Promise.resolve(data)
        default:
          return getErrorFormat(data)
      }
    })
  },
}

export default {
  namespaced: true,
  mutations,
  actions,
  state: {
    statusItems: [],
    typeItems: [],
    pageItems: [],
    current: 1,
    pages: 0,
    size: 15,
    total: 0
  }
}