import Vue from 'vue'
import api from '@/lib/api'
import { getErrorFormat } from '@/lib/utils'
import _ from 'lodash'
import { TENANT_ADMIN_PAGE, TENANT_ADMIN_SEARCH_TENANT, TENANT_ADMIN_INSERT_TENANT } from '@/store/mutation-types'
const apiTAdmin = api.tenantAdmin

const mutations = {
  [TENANT_ADMIN_PAGE] (state, data) {
    Vue.set(state, 'pageItems', data.records)
    Vue.set(state, 'current', parseInt(data.current))
    Vue.set(state, 'pages', parseInt(data.pages))
    Vue.set(state, 'size', parseInt(data.size))
    Vue.set(state, 'total', parseInt(data.total))
  },
  [TENANT_ADMIN_SEARCH_TENANT] (state, data) {
    let ids = state.currentTenantItems.map((v) => v.id)
    let items = data.filter((item) => {
      return !ids.includes(item.id)
    })
    Vue.set(state, 'tenantItems', items.concat(state.currentTenantItems))
  },
  [TENANT_ADMIN_INSERT_TENANT] (state, data) {
    Vue.set(state, 'currentTenantItems', data)
  },
}

const actions = {
  save (vuex, form) {
    return apiTAdmin.add(form).then((data) => {
      switch (data.code) {
        case 0:
          return Promise.resolve(data)
        default:
          return getErrorFormat(data)
      }
    })
  },
  modify (vuex, form) {
    return apiTAdmin.modify(form).then((data) => {
      switch (data.code) {
        case 0:
          return Promise.resolve(data)
        default:
          return getErrorFormat(data)
      }
    })
  },
  updateStatus (vuex, form) {
    return apiTAdmin.updateStatus(form).then((data) => {
      switch (data.code) {
        case 0:
          return Promise.resolve(data)
        default:
          return getErrorFormat(data)
      }
    })
  },
  detail (vuex, id) {
    return apiTAdmin.detail(id).then((data) => {
      switch (data.code) {
        case 0:
          return Promise.resolve(data)
        default:
          return getErrorFormat(data)
      }
    })
  },
  delete (vuex, form) {
    let id = form[0]
    return apiTAdmin.delete(id).then((data) => {
      switch (data.code) {
        case 0:
          return Promise.resolve(data)
        default:
          return getErrorFormat(data)
      }
    })
  },
  page ({ commit }, params) {
    return apiTAdmin.page(params).then((data) => {
      switch (data.code) {
        case 0:
          commit(TENANT_ADMIN_PAGE, data.data)
          return Promise.resolve(data)
        default:
          return getErrorFormat(data)
      }
    })
  },
  tenantSearch ({ commit }, params) {
    return apiTAdmin.tenantSearch(params).then((data) => {
      switch (data.code) {
        case 0:
          {
            let { records } = data.data
            records.forEach((v) => {
              v.label = `${v.code} ${v.name}(${v.typeName})`
            })
            commit(TENANT_ADMIN_SEARCH_TENANT, records)
            return Promise.resolve(data)
          }
        default:
          return getErrorFormat(data)
      }
    })
  },
  insertTenantItem ({ commit }, items) {
    let records = _.cloneDeep(items)
    records.forEach((v) => {
      v.label = `${v.code} ${v.name}(${v.typeName})`
    })
    commit(TENANT_ADMIN_INSERT_TENANT, records)
  }
}

export default {
  namespaced: true,
  mutations,
  actions,
  state: {
    tenantItems: [],
    currentTenantItems: [],
    pageItems: [],
    current: 1,
    pages: 0,
    size: 15,
    total: 0
  }
}