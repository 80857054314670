import http from '@/plugins/axios'

const _this = {
  page (params) {
    return http.get('tenant', { params }).then(({ data: json }) => Promise.resolve(json))
  },
  add (form) {
    return http.post('tenant', form).then(({ data: json }) => Promise.resolve(json))
  },
  modify (form) {
    return http.put('tenant', form).then(({ data: json }) => Promise.resolve(json))
  },
  statusList (params) {
    return http.get('tenant/status', { params }).then(({ data: json }) => Promise.resolve(json))
  },
  typeList (params) {
    return http.get('tenant/type', { params }).then(({ data: json }) => Promise.resolve(json))
  },
  updateStatus (form) {
    return http.put('tenant/updateStatus', form).then(({ data: json }) => Promise.resolve(json))
  },
  detail (id) {
    return http.get(`tenant/${id}`).then(({ data: json }) => Promise.resolve(json))
  },
  delete (id) {
    return http.delete(`tenant/${id}`).then(({ data: json }) => Promise.resolve(json))
  },
}

export default _this
