import http from '@/plugins/axios'

const _this = {
  page (params) {
    return http.get('user', { params }).then(({ data: json }) => Promise.resolve(json))
  },
  add (form) {
    return http.post('user', form).then(({ data: json }) => Promise.resolve(json))
  },
  resetPassword (form) {
    return http.post('user/resetPassword', form).then(({ data: json }) => Promise.resolve(json))
  },
  delete (id) {
    return http.delete(`user/${id}`).then(({ data: json }) => Promise.resolve(json))
  },
  updateStatus (form) {
    let { id, status } = form
    return http.put(`user/${id}/updateStatus/${status}`).then(({ data: json }) => Promise.resolve(json))
  },
  unlock (id) {
    return http.put(`user/${id}/unlock`, null).then(({ data: json }) => Promise.resolve(json))
  }
}

export default _this
