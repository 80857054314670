import Vue from 'vue'
import api from '@/lib/api'
import { getErrorFormat } from '@/lib/utils'
import { HOME_DATA } from '@/store/mutation-types'
const apiHome = api.home

const mutations = {
  [HOME_DATA] (state, data) {
    Vue.set(state, data.key, data.value)
  }
}

const actions = {
  init (vuex) {
    return Promise.all([
      actions.getOwnerCount(vuex),
      actions.getTenantCount(vuex),
      actions.getSpuCount(vuex),
      actions.getSkuCount(vuex),
      actions.getProcessCodeCount(vuex),
      actions.getTotalWarehouseVolume(vuex),
      actions.getTenantWarehouseVolume(vuex)
    ])
  },
  getTenantWarehouseVolume ({ commit }) {
    return apiHome.getTenantWarehouseVolume().then((json) => {
      switch (json.code) {
        case 0:
          if (Array.isArray(json.data)) {
            commit(HOME_DATA, { key: 'warehouseVolume', value: json.data })
          }
          return Promise.resolve(json)
        default:
          return getErrorFormat(json)
      }
    })
  },
  getSaleOrderInfo ({ commit }, params) {
    return apiHome.getSaleOrderInfo(params).then((json) => {
      switch (json.code) {
        case 0:
          if (Array.isArray(json.data)) {
            commit(HOME_DATA, { key: 'saleOrderInfo', value: json.data })
          }
          return Promise.resolve(json)
        default:
          return getErrorFormat(json)
      }
    })
  },
  getTeaSaleTop ({ commit }, params) {
    return apiHome.getTeaSaleTop(params).then((json) => {
      switch (json.code) {
        case 0:
          if (Array.isArray(json.data)) {
            commit(HOME_DATA, { key: 'teaSaleTop', value: json.data })
          }
          return Promise.resolve(json)
        default:
          return getErrorFormat(json)
      }
    })
  },
  getOwnerCount ({ commit }) {
    return apiHome.getOwnerCount().then((json) => {
      switch (json.code) {
        case 0:
          if (json.data) {
            let { count, newCount } = json.data
            commit(HOME_DATA, { key: 'ownerCount', value: count })
            commit(HOME_DATA, { key: 'ownerCountNew', value: newCount })
          }
          return Promise.resolve(json)
        default:
          return getErrorFormat(json)
      }
    })
  },
  getProcessCodeCount ({ commit }) {
    return apiHome.getProcessCodeCount().then((json) => {
      switch (json.code) {
        case 0:
          if (json.data) {
            let { count, newCount } = json.data
            commit(HOME_DATA, { key: 'codeCount', value: count })
            commit(HOME_DATA, { key: 'codeCountNew', value: newCount })
          }
          return Promise.resolve(json)
        default:
          return getErrorFormat(json)
      }
    })
  },
  getSkuCount ({ commit }) {
    return apiHome.getSkuCount().then((json) => {
      switch (json.code) {
        case 0:
          if (json.data) {
            let { count, newCount } = json.data
            commit(HOME_DATA, { key: 'skuCount', value: count })
            commit(HOME_DATA, { key: 'skuCountNew', value: newCount })
          }
          return Promise.resolve(json)
        default:
          return getErrorFormat(json)
      }
    })
  },
  getSpuCount ({ commit }) {
    return apiHome.getSpuCount().then((json) => {
      switch (json.code) {
        case 0:
          if (json.data) {
            let { count, newCount } = json.data
            commit(HOME_DATA, { key: 'spuCount', value: count })
            commit(HOME_DATA, { key: 'spuCountNew', value: newCount })
          }
          return Promise.resolve(json)
        default:
          return getErrorFormat(json)
      }
    })
  },
  getTenantCount ({ commit }) {
    return apiHome.getTenantCount().then((json) => {
      switch (json.code) {
        case 0:
          if (json.data) {
            let { enableCount, disableCount } = json.data
            commit(HOME_DATA, { key: 'tenantEnabled', value: enableCount })
            commit(HOME_DATA, { key: 'tenantDisabled', value: disableCount })
          }
          return Promise.resolve(json)
        default:
          return getErrorFormat(json)
      }
    })
  },
  getTotalWarehouseVolume ({ commit }) {
    return apiHome.getTotalWarehouseVolume().then((json) => {
      switch (json.code) {
        case 0:
          if (json.data) {
            let { usedVolume, surplusVolume } = json.data
            commit(HOME_DATA, { key: 'usedVolume', value: usedVolume })
            commit(HOME_DATA, { key: 'remainVolume', value: surplusVolume })
          }
          return Promise.resolve(json)
        default:
          return getErrorFormat(json)
      }
    })
  },
}

export default {
  namespaced: true,
  mutations,
  actions,
  state: {
    warehouseVolume: [],
    saleOrderInfo: [],
    teaSaleTop: [],
    usedVolume: null,
    remainVolume: null,
    tenantEnabled: null,
    tenantDisabled: null,
    spuCount: null,
    spuCountNew: null,
    skuCount: null,
    skuCountNew: null,
    codeCount: null,
    codeCountNew: null,
    ownerCount: null,
    ownerCountNew: null
  }
}