<template>
  <aside :class="['mts-sidebar', `${sidebarLayout}`]">
    <div class="mts-sidebar__body">
      <el-menu :default-active="activeItem" :collapse="fold"
        :unique-opened="true" :collapseTransition="false" class="mts-sidebar__menu">
        <sub-menu v-for="menu in menuList" :key="menu.id" :menu="menu" />
      </el-menu>
    </div>
  </aside>
</template>

<script>
import SubMenu from './menu'
import { mapState } from 'vuex'
export default {
  data () {
    return {
      sidebarLayout: ''
    }
  },
  components: {
    SubMenu
  },
  computed: {
    ...mapState('system', {
      menuList: 'navMenu',
      activeItem: s => s.sidebar.activeItem,
      fold: s => s.sidebar.fold
    })
  },
  mounted () {}
}
</script>