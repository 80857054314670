<template>
  <el-submenu v-if="menu.children && menu.children.length >= 1" :index="menu.id" :popper-append-to-body="false" class="submenuItem">
    <template slot="title">
      <svg class="icon-svg menuIcon" aria-hidden="true">
        <use :xlink:href="`#${menu.icon}`"></use>
      </svg>
      <span>{{ menu.name }}</span>
    </template>
    <sub-menu v-for="item in menu.children" :key="item.id" :menu="item"></sub-menu>
  </el-submenu>
  <el-menu-item v-else :index="menu.id" @click="gotoPage(menu.id)" class="menuItem" :disabled="!(menu.path && menu.path !== '')">
    <svg class="icon-svg menuIcon" aria-hidden="true">
      <use :xlink:href="`#${menu.icon}`"></use>
    </svg>
    <span>{{ menu.name }}</span>
  </el-menu-item>
</template>
<script>
import SubMenu from './menu'
export default {
  name: 'SubMenu',
  props: {
    menu: {
      type: Object,
      required: true
    }
  },
  components: {
    SubMenu
  },
  methods: {
    gotoPage (menuId) {
      const items = this.$router.getRoutes()
      console.log(menuId)
      const matched = items.find((item) => item.meta.menuId === menuId)
      console.log(matched)
      if (matched) this.$router.push({ name: matched.name })
    }
  }
}
</script>
