import Vue from 'vue'
import api from '@/lib/api'
import { getErrorFormat } from '@/lib/utils'
import { ERP_USER_PAGE } from '@/store/mutation-types'
const apiUser = api.erpUser

const mutations = {
  [ERP_USER_PAGE] (state, data) {
    Vue.set(state, 'pageItems', data.records)
    Vue.set(state, 'current', parseInt(data.current))
    Vue.set(state, 'pages', parseInt(data.pages))
    Vue.set(state, 'size', parseInt(data.size))
    Vue.set(state, 'total', parseInt(data.total))
  }
}

const actions = {
  page ({ commit }, params) {
    return apiUser.page(params).then((data) => {
      switch (data.code) {
        case 0:
          commit(ERP_USER_PAGE, data.data)
          return Promise.resolve(data)
        default:
          return getErrorFormat(data)
      }
    })
  },
  updateStatus (vuex, form) {
    return apiUser.updateStatus(form).then((data) => {
      switch (data.code) {
        case 0:
          return Promise.resolve(data)
        default:
          return getErrorFormat(data)
      }
    })
  },
  resetPassword (vuex, form) {
    return apiUser.resetPassword(form).then((data) => {
      switch (data.code) {
        case 0:
          return Promise.resolve(data)
        default:
          return getErrorFormat(data)
      }
    })
  }
}

export default {
  namespaced: true,
  mutations,
  actions,
  state: {
    pageItems: [],
    current: 1,
    pages: 0,
    size: 15,
    total: 0
  }
}