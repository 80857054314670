const items = [
  'system/login', 'system/userInfo', 'system/permissions',
  'system/menu', 'system/menu/route', 'system/menu/fold', 'system/menu/actived',
  'system/tab/add', 'system/tab/changed', 'system/tab/remove', 'system/tab/remove/all',
  'user/page', 'user/add', 'user/delete',
  'erp/user/page',
  'tenant/page', 'tenant/status/list', 'tenant/status', 'tenant/add', 'tenant/modify', 'tenant/delete', 'tenant/type/list', 'tenant/detail',
  'tenant/admin/page', 'tenant/admin/add', 'tenant/admin/detail', 'tenant/admin/search/tenant', 'tenant/admin/insert/tenant',
  'report/inventory/page', 'home/data'
]
const obj = {}
items.forEach((k) => {
  let v = k.split('/')
  if (typeof v === 'string') v = [v]
  v = v.map((o) => o.toUpperCase()).join('_')
  obj[v] = k
})

module.exports = obj