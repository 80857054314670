import http from '@/plugins/axios'

const _this = {
  login (f) {
    let form = new FormData()
    Object.keys(f).forEach((key) => {
      form.append(key, f[key])
    })
    return http.post('login', form).then(({ data: json }) => Promise.resolve(json))
  },
  logout (params) {
    return http.get('logout', { params }).then(({ data: json }) => Promise.resolve(json))
  },
  info (params) {
    return http.get('currentUser', { params }).then(({ data: json }) => Promise.resolve(json))
  }
}

export default _this
