<template>
  <main :class="['mts-content', { 'withTabs': isTab }]">
    <template v-if="isTab">
      <!-- 展示内容时显示Tab -->
      <el-dropdown class="tabsTools" @command="tabToolsHandler">
        <i class="el-icon-arrow-down"></i>
        <el-dropdown-menu slot="dropdown" :show-timeout="0">
          <el-dropdown-item command="closeCurrentTab">关闭当前页</el-dropdown-item>
          <el-dropdown-item command="closeOther">关闭其他页</el-dropdown-item>
          <el-dropdown-item command="closeAll">关闭所有页</el-dropdown-item>
          <el-dropdown-item command="reload">刷新当前页</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-tabs v-model="activeItem" @tab-click="tabSelected" @tab-remove="tabRemoved" type="card">
        <el-tab-pane v-for="item in contentTabs" :key="item.name" :name="item.name" :label="item.title"
          :closable="item.name !== 'home'" class="tabContent" :class="{ 'iframe': isUrl(item.iframeURL) }">
          <iframe v-if="isUrl(item.iframeURL)" :src="item.iframeURL" width="100%"
            height="100%" frameborder="0" scrolling="yes"></iframe>
          <keep-alive v-else>
            <router-view v-if="item.name === activeItem && !needRefresh" class="pageContent" />
          </keep-alive>
        </el-tab-pane>
      </el-tabs>
    </template>
    <template v-else>
      <!-- 展示内容时不显示Tab -->
      <keep-alive>
        <router-view v-if="!needRefresh" />
      </keep-alive>
    </template>
  </main>
</template>
<script>
import { validate, filterProp } from '@/lib/utils'
import { mapState, mapActions } from 'vuex'
export default {
  inject: ['refresh'],
  data () {
    // console.log(process.env)
    return {}
  },
  computed: {
    ...mapState('system', {
      activeTab: s => s.mutiTab.active,
      contentTabs: s => s.mutiTab.opened,
      needRefresh: 'needRefresh'
    }),
    isTab () {
      return this.$route.meta.isTab
    },
    activeItem: {
      get () {
        return this.activeTab
      },
      set (i) {
        // console.log(arguments)
        this.changeTab(i)
      }
    }
  },
  methods: {
    ...mapActions('system', ['changeTab', 'removeTabs', 'changeActivedMenu']),
    isUrl: (u) => validate.url(u),
    tabSelected (tab) {
      const matched = this.contentTabs.find(item => item.name === tab.name)
      // console.log(matched)
      if (matched) {
        this.$router.push(filterProp(matched, ['name', 'params', 'query']))
      }
    },
    tabRemoved (tabName = []) {
      const removeTabs = []
      typeof tabName === 'string' ? removeTabs.push(tabName) : removeTabs.push(...tabName)

      this.removeTabs(removeTabs)
      this.$nextTick(() => {
        if (this.contentTabs.length === 0) {
          this.changeActivedMenu()
          this.$router.push({ name: 'home' })
        } else if (tabName === this.activeTab) {
          // this.$router.prevRoute 见 router/index.js 文件
          // 如果关闭当前显示的tab 跳转到上一个记录的route
          // 如果上一个记录的route被关闭了 那么就跳到最后一个打开的route
          let lastOne = this.$router.prevRoute || this.contentTabs[this.contentTabs.length - 1]
          if (lastOne.name === this.$route.name || this.contentTabs.findIndex(item => item.name === lastOne.name) === -1) {
            lastOne = this.contentTabs[this.contentTabs.length - 1]
          }

          this.$router.push({ name: lastOne.name, query: lastOne.query }, () => {
            this.changeTab(lastOne.name)
          })
        }
      })
    },
    tabToolsHandler (command) {
      switch (command) {
        case 'closeCurrentTab':
          this.tabRemoved(this.activeTab)
          break
        case 'closeOther':
          this.tabRemoved(this.contentTabs.filter(tab => {
            return tab.name !== this.activeTab
          }).map(tab => tab.name))
          break
        case 'closeAll':
          this.tabRemoved()
          break
        case 'reload':
          this.refresh()
          break
      }
    }
  }
}
</script>