import { wordJoin } from '@/lib/utils'
import { getUrl } from '@/plugins/axios'
const files = require.context('./', true, /\.[js|json]+$/)

const modules = files.keys().reduce((modules, pathname) => {
  if (pathname === './index.js') return modules
  let name = pathname.replace(/^\.\/(.*)\.\w+$/, '$1')
  if (name.includes('/')) {
    let part = name.split('/')
    name = wordJoin(...part)
    if (name.endsWith('Index')) name = name.substring(0, name.length - 5)
  }
  // console.log(name)
  let value = files(pathname)
  // console.log(value)
  if (Object.keys(value).length === 1 && value.default) {
    value = value.default
  }
  modules[name] = value
  return modules
}, {})

export default { ...modules, getUrl }